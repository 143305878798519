import React, { useState } from 'react';
import Layouts from '../../components/Layouts';
import { getDirectusClient } from '../../lib/directus';
import { useEffect } from 'react';
import { readFiles } from '@directus/sdk';
import { Button, Space, Table } from 'antd';
import dayjs from "dayjs";
import Constants from '../../lib/Constants';

function Listaexportados(props) {
    const [dataSource, setDatasource]=useState([

]);

    const getExportados = async () => {
        const directus = await getDirectusClient()
        const data = await directus.request(
          readFiles({
            filter: {
              folder: {
                _eq: "e062d3cb-d1f4-4bc4-a150-e329ee9990be",
              },
              title: {
                _contains: "portabilidad",
              },
            },
            limit: -1,
            
            
          })
        );
      const sortedData = data.sort((a, b) => b.uploaded_on.localeCompare(a.uploaded_on));
        console.log(data);
        setDatasource(sortedData);
    }
    useEffect(() => {
        getExportados()
        
    }, [])
    const columns = [
      {
        title: "Nombre",
        dataIndex: "title",
        key: "name",
      },
      {
        title: "Fecha",
        dataIndex: "uploaded_on",
        key: "uploaded_on",
        render: (text) => (
          <span>
            
            {dayjs(text).format("YYYY-DD-MM HH:mm:ss")}
          </span>
        ),
      },
      {
        title: "Action",
        key: "action",
        render: (_, record) => (
          <Space size="middle">
            <Button
              href={`${Constants.URL}/assets/${record.filename_disk}?download`}
            >
              descargar
            </Button>
          </Space>
        ),
      },
    ];

    return (
      <Layouts>
        <Table dataSource={dataSource} columns={columns} />; exportados
      </Layouts>
    );
}

export default Listaexportados;