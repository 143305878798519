import React from "react";
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import Auth from "./containers/Auth";
import Groups from "./moduls/groups/Groups";
import Home from "./containers/Home";
import Products from "./moduls/products/Products";
import Orders from "./moduls/purchase_orders/ordercreate/Orders";
import OrdersCreate from "./moduls/purchase_orders/ordercreate/OrdersCreate";
import OrderAssign from "./moduls/purchase_orders/ordercreate/OrderAssign";
import OrderEnter from "./moduls/purchase_orders/orderenter/OrderEnter";
import OrdersListenter from "./moduls/purchase_orders/orderenter/OrdersListenter";
import Loan from "./moduls/loans/Loan";
import LoanCreate from "./moduls/loans/loancreate/LoanCreate";
import Loanrepay from "./moduls/loans/loancreate/Loanrepay";
import Factura from "./moduls/facturacions/Factura";
import Sellers from "./moduls/users/Sellers";
import Roles from "./moduls/permisions/Roles";
import Permisions from "./moduls/permisions/Permisions";
import Traslados from "./moduls/traslados/Traslados";
import Nuevotraslado from "./moduls/traslados/Nuevotraslado";
import Aceptartraslado from "./moduls/traslados/Aceptartraslado";
import VisualizarFactura from "./moduls/facturacions/VisualizarFactura";
import CambioSerial from "./moduls/cambioserialenfactura/CambioSerial";
import Devolverfactura from "./moduls/devolverfactura/Devolverfactura";
import Portabilidadpdf from "./moduls/postabilidades/Portabilidadpdf";
import Listaexportados from "./moduls/postabilidades/Listaexportados";
import Reporteportabilidades from "./moduls/postabilidades/Reporteportabilidades";
import Rutas from "./moduls/rutas/Rutas";
import Circuitos from "./moduls/circuitos/Circuitos";
import Vendedores from "./moduls/rutas/components/Vendedores";
import Clients from "./moduls/clientes/Clients";
import Clientsp from './moduls/clientesPromotores/Clientsp';
import Reporte from "./moduls/reportegestionrutas/Reporte";
import Reportechart from "./moduls/reportegestionrutas/Reportechart";
import Reportetree from "./moduls/reportegestionrutas/components/Reportetree";
import Reporteclient from "./moduls/reportegestionrutas/Reporteclient";
import Reporte_promotores from "./moduls/postabilidades/Reporte_promotores";
import Reporte_tropas from "./moduls/postabilidades/Reporte_tropas";

function Routers(props) {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Auth />} />
      <Route path="/groups" element={<Groups />} />
      <Route path="/products" element={<Products />} />
      <Route path="/orders_create" element={<OrdersCreate />} />
      <Route path="/orders" element={<Orders />} />
      <Route path="/order_assign/:order_id" element={<OrderAssign />} />
      <Route path="/order_enter/:order_id" element={<OrderEnter />} />
      <Route path="/order_list" element={<OrdersListenter />} />
      <Route path="/loans" element={<Loan />} />
      <Route path="/loans_create" element={<LoanCreate />} />
      <Route path="/loan_repay" element={<Loanrepay />} />
      <Route path="/factura_prestamos" element={<Factura />} />
      <Route path="/sellers" element={<Sellers />} />
      <Route path="/roles" element={<Roles />} />
      <Route path="/permisions" element={<Permisions />} />
      <Route path="/traslados" element={<Traslados />} />
      <Route path="/nuevo-traslado" element={<Nuevotraslado />} />
      <Route path="/aceptar-traslado" element={<Aceptartraslado />} />
      <Route path="/visualizarfactura" element={<VisualizarFactura />} />
      <Route path="/cambioserial" element={<CambioSerial />} />
      <Route path="/portabilidadpdf" element={<Portabilidadpdf />} />
      <Route path="/devoluciondefactura" element={<Devolverfactura />} />
      <Route path="/listaexportador" element={<Listaexportados />} />
      <Route path="/clientes" element={<Clients />} />
      <Route path="/rutas" element={<Rutas />} />
      <Route path="/clientes_promotores" element={<Clientsp />} />
      <Route path="/circuito/:idruta/:nombreruta" element={<Circuitos />} />
      <Route path="/vendedores/:idruta/:nombreruta" element={<Vendedores />} />
      <Route path="/reporte_rutas" element={<Reporte />} />
      <Route path="/reporte_rutas_chart" element={<Reportechart />} />
      <Route path="/reporte_tree" element={<Reportetree />} />
      <Route path="/reporte_client" element={<Reporteclient />} />
      <Route path="/reporte_promotores" element={<Reporte_promotores />} />
      <Route path="/reporte_tropas" element={<Reporte_tropas />} />
      <Route
        path="/reporte-portabilidades"
        element={<Reporteportabilidades />}
      />
    </Routes>
  );
}

export default Routers;
